import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { useBreakpoint } from 'ui/hooks/useBreakpoint';
import styles from './CampaignTeaser.module.scss';
import { LinkButton } from 'ui/components/1-atoms/Action';
import { Heading } from 'ui/components/1-atoms/Heading';
import { Picture } from 'ui/components/1-atoms/Media';
import { Paragraph } from 'ui/components/1-atoms/Paragraph';
import { CampaignTeaserItemProps, CampaignTeaserItem } from 'ui/components/2-molecules/CampaignTeaserItem';
import { Container } from 'ui/components/4-habitats/Container';
import { SliderOptions, Slider } from '../Slider';

interface FeaturedCampaignItem extends CampaignTeaserItemProps {
	text: string;
}

export interface CampaignTeaserProps {
	className?: string;
	heading?: string;
	featuredCampaignItems: FeaturedCampaignItem[];
	slides: CampaignTeaserItemProps[];
	sliderOptions: SliderOptions;
	spacingBottom: UI.ModuleSpacing;
	colorTheme: UI.GeneralColorTheme;
}

export const CampaignTeaser: React.FC<CampaignTeaserProps> = ({
	heading,
	featuredCampaignItems,
	slides,
	sliderOptions,
	spacingBottom,
	colorTheme = 'dark',
}) => {
	const breakpoints = {
		320: {
			slidesPerView: 1,
			slidesPerGroup: 1,
			spaceBetween: 30,
			pagination: false,
		},
		768: {
			slidesPerView: 2,
			slidesPerGroup: 2,
			spaceBetween: 30,
		},
		1024: {
			slidesPerView: 3,
			slidesPerGroup: 3,
			spaceBetween: 30,
		},
	};

	const isMobile = useBreakpoint('md', 'max');
	const [visibleFeaturedCampaignItems, setVisibleFeaturedCampaignItems] = useState(
		featuredCampaignItems?.slice(0, 2) ?? [],
	);
	const [visibleSlides, setVisibleSlides] = useState(slides ?? []);

	useEffect(() => {
		if (isMobile) {
			setVisibleFeaturedCampaignItems(featuredCampaignItems?.slice(0, 1));
			setVisibleSlides(featuredCampaignItems ? [featuredCampaignItems[1], ...slides] : slides);
		} else {
			setVisibleFeaturedCampaignItems(featuredCampaignItems?.slice(0, 2));
			setVisibleSlides(slides);
		}
	}, [isMobile, featuredCampaignItems, slides]);

	return (
		<Container
			width="fluid"
			section
			className={cx(styles.CampaignTeaser, styles[`CampaignTeaser___${colorTheme}`])}
			spacingBottom={spacingBottom}
		>
			{heading && (
				<Container width="std" className={styles.CampaignTeaser_heading}>
					<Heading headingLevel="h2" style="md">
						{heading}
					</Heading>
				</Container>
			)}

			<div className={styles.CampaignTeaser_featured}>
				<Container width="std" className={styles.CampaignTeaser_featuredInner}>
					{visibleFeaturedCampaignItems &&
						visibleFeaturedCampaignItems.map((item, index) => (
							<div className={styles.CampaignTeaser_featuredItem} key={index}>
								{item.image?.url && (
									<div className={styles.CampaignTeaser_featuredImageContainer}>
										<Picture
											className={styles.CampaignTeaser_featuredImage}
											url={item.image.url}
											altText={item.image.altText}
											aspectRatio={16 / 9}
											isCover
										/>
									</div>
								)}
								<Heading className={styles.CampaignTeaser_featuredHeading} headingLevel="h3" style="lg">
									{item.heading}
								</Heading>
								<Paragraph className={styles.CampaignTeaser_featuredText}>{item.text}</Paragraph>
								{item.link?.url && (
									<LinkButton
										className={styles.CampaignTeaser_featuredLink}
										url={item.link.url}
										style={colorTheme === 'light' ? 'primary' : 'primary-negative'}
									>
										{item.link.name}
									</LinkButton>
								)}
							</div>
						))}
				</Container>
			</div>

			{visibleSlides && (
				<div className={styles.CampaignTeaser_slides}>
					<Container width="std">
						<Slider
							slides={visibleSlides.map((slide, index) => (
								<CampaignTeaserItem key={index} {...slide} />
							))}
							sliderOptions={{ ...sliderOptions, breakpoints: breakpoints }}
						/>
					</Container>
				</div>
			)}
		</Container>
	);
};
